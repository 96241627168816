import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { useParams } from "react-router-dom";
import { Textarea } from "./ui/textarea";

import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const dateFormat = "YYYY-MM-DD";


const FormSchema = z.object({
  date: z.string().date({
    message: "A date of work is required and must be in YYYY-MM-DD format.",
  }),
  workersRequired: z
    .string({
      message: "Workers required is required.",
    })
    .min(1, { message: "Workers required must be at least 1." }),
  description: z
    .string({
      message: "Description is required.",
    })
    .min(10, { message: "Description must be at least 10 characters." }),
});

export default function ServiceSelect() {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(FormSchema),
  });

  const id = useParams();
  const date = dayjs().add(1, "day").format(dateFormat);

  async function onSubmit(data) {
    console.log(data);
  }

  return (
    <>
      <div className="flex flex-row gap-36 justify-center m-24">
        <img
          src={`/img/service/${id.id}.png`}
          alt={`${id.id}`}
          className="my-auto hidden md:inline-block w-[20rem]"
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className=" md:my-auto flex flex-col gap-2 w-[20rem]"
        >
          <input
            type="number"
            placeholder={`No of ${id.id} required`}
            className="input input-bordered decoration-none"
            required
            {...register("workersRequired")}
          />
          {<p className="text-red-500">{errors.workersRequired?.message}</p>}
          <Controller
            control={control}
            name="date"
            render={({ field }) => (
              <DatePicker
                className="h-[50px]"
                defaultValue={dayjs(date, dateFormat)}
                minDate={dayjs(date, dateFormat)}
                value={field.value ? dayjs(field.value, dateFormat) : null}
                onChange={(value) =>
                  field.onChange(value ? value.format(dateFormat) : null)
                }
              />
            )}
          />
          {<p className="text-red-500">{errors.date?.message}</p>}
          <Textarea
            placeholder="Tell us about the work."
            required
            {...register("description")}
          />
          {<p className="text-red-500">{errors.description?.message}</p>}
          <button className="btn btn-primary text-base-200" type="submit">
            Request
          </button>
        </form>
      </div>
    </>
  );
}
