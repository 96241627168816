import React from "react";

function Footer() {
  return (
    <div>
        <hr />
      <footer className="footer p-10 bg-base-200 text-base-content font-inter">
        <nav>
          <h6 className="footer-title">Legal</h6>
          <a href="/" className="link link-hover">Terms of use</a>
          <a href="/" className="link link-hover">Privacy policy</a>
          <a href="/" className="link link-hover">Cookie policy</a>
        </nav>
        <nav>
          <h6 className="footer-title">Company</h6>
          <a href="/" className="link link-hover">About us</a>
          <a href="/" className="link link-hover">Contact</a>
          <a href="/" className="link link-hover">Register</a>
          <a href="/" className="link link-hover">Login</a>
        </nav>
        <nav>
          <h6 className="footer-title font-lora">Services</h6>
          <a href="/" className="link link-hover">Workers</a>
          <a href="/" className="link link-hover">Carpenter</a>
          <a href="/" className="link link-hover">Plumbers</a>
          <a href="/" className="link link-hover">Electrician</a>
        </nav>
        <aside>
        <img
            src="/img/logo/fixup logo red.png"
            alt="FixUp"
            width="80"
            height="30"
          />
          <p>
            FixUp Solutions Ltd.
            <br />
            Copyright © 2024 - All right reserved
          </p>
        </aside>
      </footer>
    </div>
  );
}

export default Footer;
