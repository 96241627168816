function Portfolio() {
  return (
    <div>
      <h1 className="font-bold text-[1.6rem] p-5 text-center font-lora">
        Our Project Highlights
      </h1>
      <div className="my-4">
        <h2 className="text-[1.3rem] px-5 font-lora md:text-center">
          ○ Haldiram - Jor bagh
        </h2>
        <div className="md:w-[60rem] m-auto">
          <img
            src="/img/Portfolio/Jorbagh/5.jpg"
            alt=""
            className="w-[100%] p-2"
          />
          <img
            src="/img/Portfolio/Jorbagh/8.png"
            alt=""
            className="w-[50%] h-[50vw] sm:h-[20rem] inline-block p-2"
          />
          <img
            src="/img/Portfolio/Jorbagh/3.jpg"
            alt=""
            className="w-[50%] h-[50vw] sm:h-[20rem] inline-block p-2"
          />
          <img src="/img/Portfolio/Jorbagh/1.jpg" alt="" className=" p-2" />
        </div>
      </div>
      <div className="my-4">
        <h2 className="text-[1.3rem] px-5 font-lora md:text-center">
          ○ Vetic pet clinic - gurugram
        </h2>
        <div className="md:w-[60rem] m-auto">
          <img
            src="/img/Portfolio/vetic-pet-clinic/1.jpg"
            alt=""
            className="w-[100%] p-2"
          />
          <img
            src="/img/Portfolio/vetic-pet-clinic/11.png"
            alt=""
            className="w-[50%]  h-[50vw] md:h-[20rem] inline-block p-2"
          />
          <img
            src="/img/Portfolio/vetic-pet-clinic/13.png"
            alt=""
            className="w-[50%] h-[50vw] sm:h-[20rem] inline-block p-2"
          />
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
