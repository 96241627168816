import React from "react";
import AboutFixup from "../components/AboutFixup";
import HomeHero from "../components/HomeHero";
import Services from "../components/Services";
// import Reviews from "../components/Reviews";
function HomePage() {
  return (
    <>
      <HomeHero />
      <Services />
      <AboutFixup />
      {/* <Reviews/> */}
    </>
  );
}

export default HomePage;
