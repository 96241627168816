import React from "react";
import Register from "./Register";

function HomeHero() {
  return (
    <div className=" font-lora">
      <div
        className="hero min-h-[70vh] min-w-screen "
        style={{
          backgroundImage: "url(./img/fixup_hero.jpeg)",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          position: "relative",
        }}
      >
        <div className="hero-overlay bg-opacity-60 brightness-[0.2] "></div>
        <div className="hero-content flex-col lg:flex-row lg:gap-[21.5rem]">
          <div className="text-center lg:text-left ">
            <h1 className="text-5xl font-bold font-inter text-base-100 visible">
            All your crafting demands, made easy by FixUp
            </h1>
            <p className="py-6 text-base-100 text-xl ">
            Trusted and Efficient Workers for Everything in your Home
            </p>
          </div>
          <Register className="scale-90" />
        </div>
      </div>
    </div>
  );
}

export default HomeHero;
