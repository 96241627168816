import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/HomePage";
import ErrorPage from "./pages/ErrorPage";
import ServicePage from "./pages/ServicePage";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Register from "./components/Register";
import Dashboard from "./pages/Dashboard";
import { useEffect, useState } from "react";
import Portfolio from "./pages/Portfolio/Portfolio";

function App() {
  const location = useLocation();
  const [show, setShow] = useState(1);
  useEffect(() => {
    if (location.pathname === "/dashboard") setShow(0);
    else setShow(1);
  }, [location]);
  return (
    <>
      {show ? <Navbar /> : null}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/service/:id" element={<ServicePage />} />
        <Route path="/login" element={<Register />} />
        <Route path="/register" element={<Register />} />
        <Route path="*" element={<ErrorPage />} />
        <Route path="portfolio" element={<Portfolio />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
      {show ? <Footer /> : null}
    </>
  );
}

export default App;
