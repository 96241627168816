import React from 'react'
import ServiceSelect from '../components/ServiceSelect.js';
function ServicePage() {
  return (
    <>
    <ServiceSelect/>
    </>
  )
}

export default ServicePage