import React from "react";

function Services() {
  return (
    <div className="p-4 my-8">
      <h1 className="font-bold text-[1.6rem] p-5 text-center font-Inter">
        Services
      </h1>
      <div className="grid grid-flow-row md:grid-flow-col m-auto gap-4 md:my-3 ">
        <a href="/service/worker">
          <div className="card md:w-[30vw] bg-base-100 border shadow-xl m-auto hover:scale-105 hover:-translate-x-1 transition ease-in-out delay-100 duration-300 ">
            <div className="card-body ">
              <h2 className="card-title font-Inter">Labours</h2>
              <p className="font-Lora">Professional labours for your construction needs</p>
            </div>
            <figure>
              <img
                src="\img\service\worker.png"
                alt="Labours"
                className="w-[100px] m-2"
              />
            </figure>
          </div>
        </a>

        <a href="/service/electrician">
          <div className="card md:w-[30vw] bg-base-100 border shadow-xl m-auto hover:scale-105 hover:-translate-x-1 transition ease-in-out delay-100 duration-300 ">
            <div className="card-body ">
              <h2 className="card-title font-Inter">Electrician</h2>
              <p className=" font-Lora">
                Safe and reliable electrical services.
              </p>
            </div>
            <figure>
              <img
                src="\img\service\electrician.png"
                alt="carpenter"
                className="w-[100px] m-2"
              />
            </figure>
          </div>
        </a>

        <a href="/service/plumber">
          <div className="card md:w-[30vw] bg-base-100 border shadow-xl m-auto hover:scale-105 hover:-translate-x-1 transition ease-in-out delay-100 duration-300 ">
            <div className="card-body ">
              <h2 className="card-title font-Inter">Plumber</h2>
              <p className=" font-Lora">
                Efficient plumbing solutions for your home.
              </p>
            </div>
            <figure>
              <img
                src="\img\service\plumber.png"
                alt="Plumber"
                className="w-[100px] m-2"
              />
            </figure>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Services;
