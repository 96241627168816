// import React, { useState } from "react";
import axios from "axios";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

const FormSchema = z.object({
  name: z
    .string({
      message: "Name is required.",
    })
    .trim()
    .toLowerCase(),
  phone: z
    .string({
      message: "Phone number is required.",
    })
    .trim()
    .length(10, {
      message: "Phone number is not valid.",
    }),
  email: z
    .string({
      message: "Email is required.",
    })
    .trim()
    .email({
      message: "Email is not valid.",
    }),
  password: z
    .string({
      message: "Password is required.",
    })
    .trim()
    .min(6  , {
      message: "Password must be at least 8 characters.",
    }),
});

function Register() {
  const onSubmit = async (data) => {
    console.log(data);
    await axios
      .post("http://localhost:3000/api/v1/user/register", {
        fullName: data.name,
        phoneNo: data.phone,
        email: data.email,
        password: data.password,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(FormSchema),
  });
  return (
    <div className="card shrink-0 w-full max-w-sm shadow-2xl bg-base-100 scale-90 lg:scale-100 m-auto">
      <form onSubmit={handleSubmit(onSubmit)} className="card-body">
        <span className="font-inter font-bold text-lg p-2">Register</span>
        <div className="form-control gap-2">
          <input
            type="text"
            placeholder="Name "
            className="input input-bordered"
            required
            {...register("name")}
          />
          {<p className="text-red-500">{errors.name?.message}</p>}
          <input
            type="number"
            placeholder="Phone number"
            className="input input-bordered"
            required
            {...register("phone")}
          />
          { (
            <p className="text-red-500">{errors.phone?.message}</p>
          )}
          <input
            type="email"
            placeholder="email"
            className="input input-bordered"
            required
            {...register("email")}
          />
          {errors.email && (
            <p className="text-red-500">{errors.email?.message}</p>
          )}
          <input
            type="password"
            placeholder="password"
            className="input input-bordered"
            required
            {...register("password")}
          />
          {errors.password && (
            <p className="text-red-500">{errors.password?.message}</p>
          )}
        </div>
        <label className="label">
          <a href="/" className="label-text-alt link link-hover">
            Forgot password?
          </a>
        </label>
        <div className="form-control mt-6">
          <button className="btn btn-primary text-base-200" type="submit">
            Register
          </button>
        </div>
      </form>
    </div>
  );
}

export default Register;
