import React from "react";

function Navbar() {
  return (
    <>
      <div className="drawer">
        <input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content flex flex-col">
          <div className="w-full navbar bg-base-100">
            <div className="flex-none lg:hidden">
              <label
                htmlFor="my-drawer-3"
                aria-label="open sidebar"
                className="btn btn-square btn-ghost"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="inline-block w-6 h-6 stroke-current"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
              </label>
            </div>
            <div className="flex-1 px-2 mx-2">
              <a href="/" className="p-2">
                <img
                  src="/img/logo/fixup logo red.png"
                  alt="FixUp"
                  width="80"
                  height="30"
                />
              </a>
            </div>
            <div className="flex-none hidden lg:block">
              <ul className="menu menu-horizontal">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/portfolio"> What's FixUp</a>
                </li>
                <li>
                  <details>
                    <summary>services</summary>
                    <ul className="p-2 rounded-t-none z-10">
                      <li>
                        <a href="/service/worker">Workers</a>
                      </li>
                      <li>
                        <a href="/service/electrician">Electrician</a>
                      </li>
                      <li>
                        <a href="/service/plumber">Plumbers</a>
                      </li>
                    </ul>
                  </details>
                </li>
                <li>
                  <a
                    href="/login"
                    className="btn btn-sm text-base-100 btn-primary"
                  >
                    Login
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="drawer-side z-50">
          <label
            htmlFor="my-drawer-3"
            aria-label="close sidebar"
            className="drawer-overlay"
          ></label>
          <ul className="menu p-4 w-80 min-h-full bg-base-200">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/portfolio"> What's FixUp</a>
            </li>
            <li>
              <details>
                <summary>services</summary>
                <ul className="p-2 rounded-t-none">
                  <li>
                    <a href="/service/worker">Workers</a>
                  </li>
                  <li>
                    <a href="/service/electrician">Electrician</a>
                  </li>
                  <li>
                    <a href="/service/plumber">Plumbers</a>
                  </li>
                </ul>
              </details>
            </li>
            <li>
              <a href="/" className="btn btn-sm text-base-100 btn-primary">
                Login
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Navbar;
