import React from "react";
import "./AboutFixup.css";

function AboutFixup() {
  return (
    <>
      <div className=" bg-[#dc3545]  text-base-100 ">
        <h1 className=" font-bold text-[2.8rem] p-5 text-center font-Inter ">
          Why fixup ?
        </h1>
        {/* <div className="grid lg:grid-col-3 lg:grid-rows-2 grid-flow-row lg:text-[1.2rem] lg:gap-[8vw] lg:p-[4vw] font-lora mr-[60px] gap-[30px] scale-50 lg:scale-100 mt-[-5rem] lg:mt-auto"> */}
        <div className="flex flex-col gap-[2.5rem] pb-[2rem] md:grid md:grid-col-3 md:grid-rows-2 md:grid-flow-col scale-90 text-[1.2rem] lg:gap-[8vw] lg:p-[4vw] font-lora lg:scale-100 lg:mt-auto">
          <div>
            <svg
              width="100"
              height="133"
              viewBox="0 0 100 133"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className=" m-auto"
            >
              <g clip-path="url(#clip0_121_63)">
                <path
                  d="M27.7407 129.498C27.7407 131.126 29.4168 132.457 31.4654 132.457H86.5165C88.5651 132.457 90.2412 131.126 90.2412 129.498V122.723H27.7407V129.498Z"
                  fill="black"
                />
                <path
                  d="M26.493 47.7668L26.5118 26.1246C26.5118 23.6245 27.7035 21.2872 29.8827 19.5417C32.3038 17.6186 35.7677 16.5239 39.418 16.5239C42.2674 16.5239 45.005 17.2191 47.1279 18.4618C49.6982 19.9707 51.2996 22.2488 51.6348 24.8524C51.6348 24.9115 53.1061 36.1987 54.4284 46.3023C63.6843 41.9827 69.9232 33.9945 69.9232 24.8524C69.9232 11.1244 55.9183 0 38.6356 0C21.3529 0 7.34793 11.1244 7.34793 24.8524C7.32931 35.1483 15.2257 43.9946 26.493 47.7668Z"
                  fill="black"
                />
                <path
                  d="M100 76.0069C100 73.1222 97.672 70.5629 94.2081 69.6753C83.872 66.9977 63.0136 61.6574 59.8288 61.1545C58.8417 61.0065 57.8733 60.9326 56.9421 60.9326C54.4279 60.9326 52.2303 61.4799 50.9082 62.4119H50.8894C50.8894 62.4119 50.8894 62.4119 50.8894 62.3971C50.8708 62.2786 46.0661 25.37 46.0661 25.3109C45.6748 22.3522 42.5835 20.9469 39.3987 20.9469C35.7859 20.9469 32.0612 22.7517 32.0796 26.1097L32.0424 85.9182C32.0424 86.6282 31.3905 87.2199 30.4966 87.3236C30.478 87.3236 30.478 87.3236 30.4594 87.3236C30.3851 87.3384 30.2919 87.3384 30.2173 87.3384C30.1986 87.3384 30.18 87.3384 30.1614 87.3384C27.6286 87.2792 21.9111 87.2199 16.3427 80.8146C15.5977 79.9713 14.9273 79.2022 14.3127 78.492C12.3759 76.2878 9.78719 75.3412 7.3475 75.3412C3.06408 75.3412 -0.791002 78.2847 0.140177 82.6786C1.48107 89.0838 10.3087 104.01 27.7031 118.004H90.2038L96.517 109.528C98.7706 106.525 99.9624 103.093 99.9624 99.5869L100 76.0069Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_121_63">
                  <rect width="100" height="133" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <h2 className="m-auto text-center">
              All construction help at one place
            </h2>
          </div>
          <div>
            <svg
              width="135"
              height="138"
              viewBox="0 0 135 138"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="m-auto"
            >
              <path
                d="M24.1089 19.7149H101.251V59.1433H110.894V9.85681H14.4651V128.143H67.5014V118.285H24.1089V19.7149Z"
                fill="black"
              />
              <path
                d="M94.0175 64.8923L67.5 78.4471V90.3388C67.5 105.202 75.7147 118.789 88.719 125.436L94.0175 128.143L99.316 125.436C112.32 118.789 120.535 105.202 120.535 90.3388V78.4471L94.0175 64.8923ZM110.892 90.3374C110.892 101.539 104.804 111.609 95.0036 116.618L94.0175 117.122L93.0313 116.618C83.2307 111.609 77.1425 101.538 77.1425 90.3374V84.5385L94.0175 75.9135L110.892 84.5385V90.3374Z"
                fill="black"
              />
              <path
                d="M86.7309 92.0732L81.9413 97.6134L94.235 108.722L109.255 91.0557L103.798 86.2042L93.5626 98.2441L86.7309 92.0732ZM33.75 34.5H91.6075V44.3568H33.75V34.5ZM33.75 59.1432H72.3212V69H33.75V59.1432ZM33.75 83.7851H53.0363V93.6419H33.75V83.7851Z"
                fill="black"
              />
            </svg>
            <h2 className="m-auto text-center">Transparent invoices</h2>
          </div>
          <div>
            <svg
              width="126"
              height="128"
              viewBox="0 0 126 128"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="m-auto"
            >
              <path
                d="M120.75 64C120.75 65.8907 120.662 67.7606 120.49 69.6054C117.515 66.5819 114.032 64.0747 110.182 62.2267C109.264 36.5588 88.4914 16.0365 63 16.0365C36.9244 16.0365 15.7859 37.5105 15.7859 64C15.7859 89.896 35.9875 110.998 61.2544 111.931C63.0735 115.842 65.5415 119.38 68.5177 122.402C66.7018 122.577 64.8611 122.667 63 122.667C31.1056 122.667 5.25 96.4006 5.25 64C5.25 31.5993 31.1056 5.33337 63 5.33337C94.8943 5.33337 120.75 31.5993 120.75 64Z"
                fill="black"
              />
              <path
                d="M68.25 63.3664L72.6736 65.961C69.7447 68.161 67.1486 70.793 64.9772 73.7637L60.4663 71.1178C59.5544 70.5829 58.8604 69.8122 58.4152 68.9253C57.75 67.8794 57.75 66.489 57.75 66.489V32C57.75 29.0544 60.1004 26.6666 63 26.6666C65.8996 26.6666 68.25 29.0545 68.25 32V63.3664Z"
                fill="black"
              />
              <path
                d="M80.2878 94.4378C82.338 92.3552 85.6623 92.3552 87.7124 94.4378L91.0782 97.857L101.048 87.7285C103.099 85.6458 106.423 85.6458 108.473 87.7285C110.524 89.8117 110.524 93.1882 108.473 95.2714L95.1369 108.819C94.6833 109.28 94.1678 109.639 93.6171 109.895C91.6063 111.09 88.9808 110.812 87.2567 109.06L80.2878 101.98C78.2377 99.8976 78.2377 96.5205 80.2878 94.4378Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M94.5 128C111.897 128 126 113.673 126 96C126 78.3269 111.897 64 94.5 64C77.1031 64 63 78.3269 63 96C63 113.673 77.1031 128 94.5 128ZM94.5 117.43C82.8497 117.43 73.405 107.835 73.405 96C73.405 84.1648 82.8497 74.5701 94.5 74.5701C106.15 74.5701 115.595 84.1648 115.595 96C115.595 107.835 106.15 117.43 94.5 117.43Z"
                fill="black"
              />
            </svg>
            <h2 className="m-auto text-center">Time saving</h2>
          </div>
          <div>
            <svg
              width="135"
              height="138"
              viewBox="0 0 135 138"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="m-auto"
            >
              <g clip-path="url(#clip0_121_36)">
                <path
                  d="M49.3809 43.9708L54.7872 52.7845L64.6581 55.3173L58.1285 63.297L58.8225 73.6759L49.3809 69.7938L39.9388 73.6759L40.6328 63.297L34.1038 55.3173L43.9746 52.7845L49.3809 43.9708Z"
                  fill="#CEE8FA"
                />
                <path
                  d="M39.9394 77.7347C39.1131 77.7347 38.2941 77.4714 37.6051 76.9596C36.4993 76.138 35.8844 74.7931 35.9775 73.3991L36.5628 64.6487L31.0579 57.9218C30.1812 56.8498 29.9051 55.3922 30.3273 54.0634C30.7497 52.7346 31.8109 51.7212 33.137 51.3811L41.459 49.2461L46.0168 41.8151C46.7429 40.6311 48.0141 39.9122 49.381 39.9122C50.7479 39.9122 52.019 40.6311 52.7452 41.8151L57.303 49.2461L65.6245 51.3811C66.9502 51.7212 68.0115 52.7346 68.4342 54.0634C68.8563 55.3922 68.5803 56.8501 67.7035 57.9218L62.1986 64.6493L62.784 73.3996C62.8773 74.7939 62.2627 76.1388 61.1563 76.9601C60.0502 77.7811 58.6087 77.9636 57.341 77.4423L49.3807 74.1694L41.4202 77.4423C40.9424 77.638 40.4396 77.7347 39.9394 77.7347ZM41.2119 57.6778L43.6788 60.6923C44.3359 61.496 44.6639 62.5281 44.5945 63.5733L44.3325 67.4944L47.8997 66.0277C48.8507 65.6368 49.912 65.6368 50.8628 66.0277L54.4295 67.4942L54.1674 63.5733C54.0975 62.5281 54.4256 61.496 55.0832 60.6923L57.5501 57.6778L53.821 56.7213C52.8272 56.4663 51.9679 55.8283 51.4234 54.9408L49.381 51.611L47.3386 54.9408C46.7941 55.8283 45.9351 56.466 44.941 56.7213L41.2119 57.6778Z"
                  fill="black"
                />
                <path
                  d="M100.896 55.7882H78.2638C76.0714 55.7882 74.2932 53.971 74.2932 51.7293C74.2932 49.4876 76.0711 47.6704 78.2638 47.6704H100.896C103.089 47.6704 104.867 49.4876 104.867 51.7293C104.867 53.971 103.089 55.7882 100.896 55.7882Z"
                  fill="black"
                />
                <path
                  d="M92.9503 69.9763H78.2643C76.0719 69.9763 74.2937 68.1591 74.2937 65.9174C74.2937 63.6757 76.0716 61.8585 78.2643 61.8585H92.9503C95.1428 61.8585 96.921 63.6757 96.921 65.9174C96.9207 68.1591 95.1428 69.9763 92.9503 69.9763Z"
                  fill="black"
                />
                <path
                  d="M49.3809 87.8367L54.7872 96.6503L64.6581 99.1826L58.1285 107.163L58.8225 117.541L49.3809 113.659L39.9388 117.541L40.6328 107.163L34.1038 99.1826L43.9746 96.6503L49.3809 87.8367Z"
                  fill="#CEE8FA"
                />
                <path
                  d="M58.8225 121.6C58.3224 121.6 57.8195 121.504 57.3415 121.307L49.3812 118.034L41.4207 121.307C40.153 121.829 38.7112 121.646 37.6054 120.825C36.4996 120.004 35.8844 118.659 35.9778 117.265L36.5631 108.514L31.0579 101.787C30.1812 100.715 29.9051 99.2573 30.3273 97.9285C30.7497 96.5998 31.8109 95.5863 33.137 95.2462L41.459 93.1112L46.0168 85.6802C46.7429 84.4962 48.0141 83.7773 49.381 83.7773C50.7479 83.7773 52.019 84.4962 52.7452 85.6802L57.303 93.1112L65.6245 95.2462C66.9502 95.5863 68.0115 96.5998 68.4342 97.9285C68.8563 99.2573 68.5803 100.715 67.7035 101.787L62.1986 108.514L62.784 117.265C62.8773 118.659 62.2627 120.004 61.1563 120.825C60.4676 121.337 59.6489 121.6 58.8225 121.6ZM41.2119 101.544L43.6788 104.558C44.3359 105.362 44.6639 106.394 44.5945 107.439L44.3325 111.36L47.8997 109.894C48.8507 109.503 49.912 109.503 50.8628 109.894L54.4295 111.36L54.1674 107.439C54.0975 106.394 54.4256 105.362 55.0832 104.558L57.5501 101.544L53.821 100.587C52.8272 100.332 51.9679 99.6942 51.4234 98.8067L49.381 95.4769L47.3386 98.8067C46.7941 99.6942 45.9351 100.332 44.941 100.587L41.2119 101.544Z"
                  fill="black"
                />
                <path
                  d="M100.896 99.6538H78.2638C76.0714 99.6538 74.2932 97.8366 74.2932 95.5949C74.2932 93.3532 76.0711 91.536 78.2638 91.536H100.896C103.089 91.536 104.867 93.3532 104.867 95.5949C104.867 97.8366 103.089 99.6538 100.896 99.6538Z"
                  fill="black"
                />
                <path
                  d="M92.9503 113.841H78.2643C76.0719 113.841 74.2937 112.024 74.2937 109.783C74.2937 107.541 76.0716 105.724 78.2643 105.724H92.9503C95.1428 105.724 96.921 107.541 96.921 109.783C96.9207 112.024 95.1428 113.841 92.9503 113.841Z"
                  fill="black"
                />
                <path
                  d="M45.6606 15.4358H17.8665V28.4116H45.6606V15.4358Z"
                  fill="#CEE8FA"
                />
                <path
                  d="M117.133 15.4358H89.3384V28.4116H117.133V15.4358Z"
                  fill="#CEE8FA"
                />
                <path
                  d="M85.3677 28.4118C85.3677 30.6535 87.1456 32.4707 89.3383 32.4707H117.134C119.326 32.4707 121.104 30.6535 121.104 28.4118V15.4361C121.104 13.1944 119.326 11.3772 117.134 11.3772H93.309V4.05887C93.3087 1.81718 91.5308 0 89.3383 0H45.6616C43.4692 0 41.691 1.81718 41.691 4.05887V11.3775H17.8664C15.674 11.3775 13.8958 13.1946 13.8958 15.4363V28.4121V133.941C13.8958 136.183 15.6737 138 17.8664 138H117.134C119.326 138 121.104 136.183 121.104 133.941V51.7292C121.104 49.4876 119.326 47.6704 117.134 47.6704C114.941 47.6704 113.163 49.4876 113.163 51.7292V129.882H21.837V32.4707H45.6616H73.6027C75.7951 32.4707 77.5733 30.6535 77.5733 28.4118C77.5733 26.1701 75.7954 24.353 73.6027 24.353H49.6323V15.4361V8.11774H85.3674V15.4361V28.4118H85.3677ZM21.837 19.4949H41.6912V24.353H21.837V19.4949ZM113.163 24.353H93.3087V19.4949H113.163V24.353Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_121_36">
                  <rect width="135" height="138" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <h2 className="m-auto text-center">Competent</h2>
          </div>
          <div>
            <svg
              width="135"
              height="138"
              viewBox="0 0 135 138"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="m-auto"
            >
              <path
                d="M56.25 38.3334V61.3334H52.5V47.2075C49.9625 48.1452 47.5147 49.3193 45.1875 50.715C39.3833 54.2588 34.4755 59.1479 30.8602 64.9877C27.2449 70.8275 25.0234 77.4543 24.375 84.3334H110.381C110.512 85.5984 110.587 86.8825 110.625 88.1667C110.581 79.2367 107.862 70.5348 102.834 63.2323C97.8052 55.9299 90.7089 50.3775 82.5 47.3225V61.3334H78.75V38.3334H69.2437V49.8334H65.4937V38.3334H56.25ZM110.625 88.1667H18.75C17.7554 88.1667 16.8016 88.5706 16.0984 89.2895C15.3951 90.0084 15 90.9834 15 92V95.8334C15 96.85 15.3951 97.8251 16.0984 98.544C16.8016 99.2628 17.7554 99.6667 18.75 99.6667H116.25C117.245 99.6667 118.198 99.2628 118.902 98.544C119.605 97.8251 120 96.85 120 95.8334V92C120 90.9834 119.605 90.0084 118.902 89.2895C118.198 88.5706 117.245 88.1667 116.25 88.1667H110.625ZM24.15 88.1667C24.15 87.5917 24.15 87.0167 24.15 86.4417C24.1875 87.0167 24.1687 87.5917 24.15 88.1667ZM67.275 65.1667C68.7584 65.1667 70.2084 65.6164 71.4418 66.4588C72.6751 67.3012 73.6364 68.4986 74.2041 69.8995C74.7718 71.3004 74.9203 72.8419 74.6309 74.3291C74.3415 75.8163 73.6272 77.1823 72.5783 78.2545C71.5294 79.3267 70.193 80.0569 68.7382 80.3527C67.2833 80.6485 65.7753 80.4967 64.4049 79.9165C63.0344 79.3362 61.8631 78.3535 61.039 77.0927C60.2149 75.832 59.775 74.3497 59.775 72.8334C59.7749 71.8185 59.972 70.8136 60.3547 69.8772C60.7375 68.9407 61.2983 68.0912 62.0047 67.3781C62.7111 66.6649 63.549 66.1022 64.4698 65.7226C65.3906 65.343 66.376 65.154 67.3687 65.1667H67.275Z"
                fill="black"
              />
            </svg>
            <h2 className="m-auto text-center">Friendly workers</h2>
          </div>
          <div>
            <svg
              width="135"
              height="138"
              viewBox="0 0 135 138"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="m-auto"
            >
              <g clip-path="url(#clip0_121_27)">
                <path
                  d="M130.781 30.1875H4.21875C3.09987 30.1875 2.02681 30.6419 1.23564 31.4506C0.444474 32.2594 0 33.3563 0 34.5L0 103.5C0 104.644 0.444474 105.741 1.23564 106.549C2.02681 107.358 3.09987 107.812 4.21875 107.812H130.781C131.9 107.812 132.973 107.358 133.764 106.549C134.556 105.741 135 104.644 135 103.5V34.5C135 33.3563 134.556 32.2594 133.764 31.4506C132.973 30.6419 131.9 30.1875 130.781 30.1875ZM105.848 99.1875H29.1516C28.2723 93.8985 25.8065 89.0196 22.0955 85.226C18.3844 81.4325 13.6116 78.9119 8.4375 78.0131V59.9869C13.6116 59.0881 18.3844 56.5675 22.0955 52.774C25.8065 48.9804 28.2723 44.1015 29.1516 38.8125H105.848C106.728 44.1015 109.193 48.9804 112.905 52.774C116.616 56.5675 121.388 59.0881 126.562 59.9869V78.0131C121.388 78.9119 116.616 81.4325 112.905 85.226C109.193 89.0196 106.728 93.8985 105.848 99.1875ZM126.562 51.1462C123.661 50.3719 121.013 48.821 118.892 46.6531C116.772 44.4853 115.254 41.7789 114.497 38.8125H126.562V51.1462ZM20.5031 38.8125C19.7456 41.7789 18.2284 44.4853 16.1077 46.6531C13.987 48.821 11.3394 50.3719 8.4375 51.1462V38.8125H20.5031ZM8.4375 86.8537C11.3394 87.6281 13.987 89.179 16.1077 91.3469C18.2284 93.5147 19.7456 96.2211 20.5031 99.1875H8.4375V86.8537ZM114.497 99.1875C115.254 96.2211 116.772 93.5147 118.892 91.3469C121.013 89.179 123.661 87.6281 126.562 86.8537V99.1875H114.497Z"
                  fill="black"
                />
                <path
                  d="M31.6828 3.0619C31.2023 2.77799 30.6718 2.59376 30.1217 2.51976C29.5715 2.44576 29.0124 2.48344 28.4765 2.63065C27.3957 2.91767 26.4702 3.63104 25.9031 4.6144L17.4656 19.4063C16.9061 20.4013 16.7563 21.5828 17.049 22.6908C17.3418 23.7988 18.0531 24.7425 19.0265 25.3144C19.9999 25.8863 21.1557 26.0394 22.2396 25.7402C23.3235 25.441 24.2468 24.7138 24.8062 23.7188L31.1343 12.6788L52.5234 25.3144C53.4968 25.8863 54.6526 26.0394 55.7365 25.7402C56.8204 25.441 57.7436 24.7138 58.3031 23.7188C58.8625 22.7237 59.0123 21.5422 58.7196 20.4342C58.4269 19.3263 57.7156 18.3825 56.7421 17.8107L31.6828 3.0619Z"
                  fill="black"
                />
                <path
                  d="M103.317 134.938C103.956 135.326 104.684 135.534 105.427 135.542C105.79 135.605 106.16 135.605 106.523 135.542C107.604 135.255 108.53 134.541 109.097 133.558L117.534 118.766C118.094 117.771 118.244 116.59 117.951 115.482C117.658 114.374 116.947 113.43 115.973 112.858C115 112.286 113.844 112.133 112.76 112.432C111.676 112.732 110.753 113.459 110.194 114.454L103.95 125.494L82.5609 112.858C81.5875 112.286 80.4317 112.133 79.3478 112.432C78.2639 112.732 77.3407 113.459 76.7812 114.454C76.2218 115.449 76.072 116.63 76.3647 117.738C76.6574 118.846 77.3687 119.79 78.3422 120.362L103.317 134.938Z"
                  fill="black"
                />
                <path
                  d="M67.5 43.125C62.4937 43.125 57.5998 44.6425 53.4371 47.4857C49.2745 50.3289 46.0302 54.37 44.1143 59.0981C42.1985 63.8261 41.6972 69.0287 42.6739 74.048C43.6506 79.0672 46.0614 83.6777 49.6014 87.2964C53.1414 90.9151 57.6516 93.3794 62.5618 94.3778C67.4719 95.3762 72.5614 94.8638 77.1867 92.9054C81.8119 90.947 85.7652 87.6305 88.5466 83.3754C91.328 79.1203 92.8125 74.1176 92.8125 69C92.8125 62.1375 90.1457 55.5561 85.3987 50.7036C80.6516 45.8511 74.2133 43.125 67.5 43.125ZM67.5 86.25C64.1625 86.25 60.8998 85.2383 58.1248 83.3428C55.3497 81.4474 53.1868 78.7533 51.9095 75.6013C50.6323 72.4493 50.2981 68.9809 50.9493 65.6347C51.6004 62.2885 53.2076 59.2149 55.5676 56.8024C57.9276 54.39 60.9344 52.747 64.2079 52.0815C67.4813 51.4159 70.8743 51.7575 73.9578 53.0631C77.0413 54.3687 79.6768 56.5797 81.5311 59.4164C83.3853 62.2532 84.375 65.5883 84.375 69C84.375 73.575 82.5971 77.9626 79.4324 81.1976C76.2678 84.4326 71.9755 86.25 67.5 86.25Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_121_27">
                  <rect width="135" height="138" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <h2 className="m-auto text-center">
              All services at economic prices
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutFixup;
